import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Row, Card, Col, CardBody,
} from "reactstrap"
import ReactExport from "react-export-excel";
import {Link, withRouter} from "react-router-dom"
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {getLogisticProduct, nullProductObject, updateProduct} from "../../store/actions";

import {shop} from '../../helpers/status'
import Nulled from "../../components/Null/Nulled";
import {branchAddress} from "../../helpers/branch";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [product, setProduct] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        setId(id)
        setKey(key)
        /*
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getLogisticProduct(item))
        }*/
    }

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }


    const handleChange = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            userInformation: {...prevState.userInformation, [name]: value}
        }));
    };
    useEffect(() => {
        if (modal_xlarge === true) {
            let item = {
                id: id
            }
            dispatch(getLogisticProduct(item))
            setProduct(props.products.product)
        } else {
            dispatch(nullProductObject())

        }

    }, [modal_xlarge, props.products.product._id])


    const Columns = [
        {
            text: "გან.",
            formatter: (cellContent, row, key) => (

                <>
                    {
                        row.custom === true
                            ?
                            <>
                                {
                                    row.clearance === true
                                        ? <span style={{color: 'red', fontWeight: "strong"}}>მსურს</span>
                                        : <span style={{color: 'red', fontWeight: "strong"}}>განს.</span>
                                }
                            </>
                            :
                            <>
                                {
                                    row.clearance === true
                                        ? <span style={{color: 'red', fontWeight: "strong"}}>მსურს</span>
                                        : <>---</>
                                }
                            </>
                    }


                </>
            ),
        },
        {
            dataField: "productOrderId",
            text: "თრექინგ კოდი",
        },
        {
            dataField: "productName",
            text: "დასახელება",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        row.productName
                            ? shop(row.productName)
                            : null
                    }
                </>
            ),
        },
        {
            text: "მყიდველი",
            sort: true,
            formatter: (cellContent, row) => (
                <>

                    {
                        parseInt(row.userInformation.userType) === 1 || isEmpty(row.userInformation.userType)
                            ? <>
                                {
                                    row.userInformation.nameKa
                                }
                                {' '}
                                {
                                    row.userInformation.lastNameKa
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.userInformation.userType) === 2
                            ? <>
                                {
                                    row.userInformation.LTDName
                                }
                            </>
                            : null
                    }

                </>
            ),
        },

        {
            text: "მყიდველი",
            sort: true,
            formatter: (cellContent, row) => (
                <>

                    {
                        parseInt(row.userInformation.userType) === 1 || isEmpty(row.userInformation.userType)
                            ? <>
                                {
                                    row.userInformation.nameEn
                                }
                                {' '}
                                {
                                    row.userInformation.lastNameEn
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.userInformation.userType) === 2
                            ? <>
                                {
                                    row.userInformation.LTDNameEn
                                }
                            </>
                            : null
                    }

                </>
            ),
        },

        {
            dataField: "userInformation.address",
            text: "მისამართი.",
            sort: true,
        },
        {
            dataField: "phone",
            text: "ტელეფონი",
            sort: true,
        },
        {
            text: "პასპორტი/ორგ. ID",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.userInformation.userType) === 1 || isEmpty(row.userInformation.userType)
                            ? <>
                                {
                                    row.userInformation.passportId
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.userInformation.userType) === 2
                            ? <>
                                {
                                    row.userInformation.orgId
                                }
                            </>
                            : null
                    }

                </>
            ),
        },


        {
            dataField: "price",
            text: "თანხა",
            sort: true,
        },
        {
            dataField: "currency",
            text: "ვალუტა",
            sort: true,
        },
        {
            dataField: "userInformation.AXID",
            text: "AX კოდი",
            sort: true,
        },
        {
            text: "ამანათის ტიპი",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        row.personalParcel
                            ? <span style={{color: 'red', fontWeight: 'strong'}}> პერსონალური</span>
                            : null
                    }
                </>
            ),
        },

        {
            text: "ფილიალი",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {branchAddress(row.branch)}
                </>
            ),
        },
        {
            dataField: "userInformation.city",
            text: "მისამართი",
            sort: true,
        },

    ]

    return (
        <>
            {
                !isEmpty(props.logisticProducts)
                    ?
                    <>
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <ExcelFile
                                            element={<button
                                                className='waves-effect waves-light btn btn-primary'>Download EXEL
                                                files</button>}>
                                            <ExcelSheet data={props.logisticProducts} name="Employees">
                                                <ExcelColumn label="განბაჟება"
                                                             value={(col) => col.custom === false ? '----' : 'განსაბაჟებელი'}/>
                                                <ExcelColumn label="წონა" value="weight"/>
                                                <ExcelColumn label="თრექინგ კოდი" value="productOrderId"/>
                                                <ExcelColumn label="passportId "
                                                             value={(col) =>
                                                                 parseInt(col.userInformation.userType) === 1 || isEmpty(col.userInformation.userType)
                                                                     ? `${col.userInformation.passportId}`
                                                                     :
                                                                     parseInt(col.userInformation.userType) === 2
                                                                         ? col.userInformation.indEntrepreneur
                                                                         ? `${col.userInformation.passportId}`
                                                                         : col.userInformation.orgId
                                                                         : null
                                                             }/>
                                                <ExcelColumn label="მყიდველი ქართულად."
                                                             value={(col) =>
                                                                 parseInt(col.userInformation.userType) === 1 || isEmpty(col.userInformation.userType)
                                                                     ? `${col.userInformation.nameKa} ${col.userInformation.lastNameKa}`
                                                                     :
                                                                     parseInt(col.userInformation.userType) === 2
                                                                         ? col.userInformation.indEntrepreneur
                                                                         ? `${col.userInformation.nameKa} ${col.userInformation.lastNameKa}`
                                                                         : col.userInformation.LTDName
                                                                         : null
                                                             }/>
                                                <ExcelColumn label="მყიდველი ინგლისურად"
                                                             value={(col) =>
                                                                 parseInt(col.userInformation.userType) === 1 || isEmpty(col.userInformation.userType)
                                                                     ? `${col.userInformation.nameEn} ${col.userInformation.lastNameEn}`
                                                                     :
                                                                     parseInt(col.userInformation.userType) === 2
                                                                         ? col.userInformation.indEntrepreneur
                                                                         ? `${col.userInformation.nameEn} ${col.userInformation.lastNameEn}`
                                                                         : col.userInformation.LTDNameEn
                                                                         : null
                                                             }/>
                                                <ExcelColumn label="ტელეფონი"
                                                             value={(col) => col.userInformation.phone ? `${col.userInformation.phone}` : `${col.userInformation.phone}`}/>
                                                <ExcelColumn label="დასახელება"
                                                             value={(col) => col.productName ? `${shop(col.productName)}` : ``}/>
                                                <ExcelColumn label="რაოდენობა" value="quantity"/>

                                                <ExcelColumn label="თანხა" value="price"/>
                                                <ExcelColumn label="ვალუტა" value="currency"/>
                                                {/*<ExcelColumn label="მაღაზია"*/}
                                                {/*             value={(col) => col.shop === '_OTHER_SHOP_' ? `${col.otherShop}` : `${col.shop}`}/>*/}
                                                <ExcelColumn label="მაღაზია"
                                                             value={(col) => col.personalParcel
                                                                 ? `${col.senderName}` :
                                                                 col.shop === '_OTHER_SHOP_'
                                                                     ? col.otherShop
                                                                     : col.shop
                                                             }/>
                                                <ExcelColumn label="AXID"
                                                             value={(col) => col.userInformation.AXID ? `${col.userInformation.AXID}` : `${col.userInformation.AXID}`}/>
                                                <ExcelColumn label="ამანათის სტატუსი"
                                                             value={(col) =>
                                                                 col.personalParcel
                                                                     ? `პერსონალური`
                                                                     : null
                                                             }/>
                                                <ExcelColumn label="ფილიალი!"
                                                             value={(col) =>
                                                                 branchAddress(col.branch)
                                                             }/>
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <BootstrapTable
                                _id={'_id'}
                                keyField="_id"
                                data={props.logisticProducts}
                                columns={Columns}
                            />

                        </Card>

                    </>
                    : <Nulled/>
            }

        </>
    )
}

const mapStateToProps = state => {
    const {
        products,
    } = state
    return {
        products,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));
