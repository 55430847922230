import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {notification} from 'antd';


import ClipLoader from "react-spinners/ClipLoader";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getAllLogisticProducts,  changeStatusStep5Cheked} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";



const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};
const Arrived = props => {
    const dispatch = useDispatch();
    const [sq1, setsq1] = useState(false)
    const [country, setCountry] = useState(1)
    const [creater, setCreater] = useState('all')
    const [uniqArray, setUniqArray] = useState([])
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(1500)
    const [selectProducts, setSelectProducts] = useState([])
    useEffect(() => {
        let item = {
            country: country,
            status: 'arrived',
            creater: creater,
            skip: skip,
            limit: limit,
        }
        dispatch(getAllLogisticProducts(item))
    }, [])


    function onRowSelect(row, isSelected) {

        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row._id]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row._id))
        }
    }

    const handleChangeStatusChecked = () => {
        let uniqueSet = new Set(selectProducts)
        let uniArray = [...uniqueSet]
        setUniqArray(uniqArray)

        if (uniArray.length === 0) {
            modalWarning('warning');
        }else {
            let item = {
                ids :uniArray,
                country : country,
            }
            dispatch(changeStatusStep5Cheked(item))
        }

    }
    const handleChangeStatusAll = () => {
        let item = {

            status: 'arrived',
        }
       // dispatch(changeStatusStep2All(item))
    }


    const handleChangeCountry = (i) => {

        let country = i.target.value
        if (country !== 0)
            setCountry(country)
        let item = {
            country: country,
            status: 'arrived',
            creater: creater,
            skip: skip,
            limit: limit,
        }
        dispatch(getAllLogisticProducts(item))
    }
    const handleChangeCreator = (i) => {
        setCreater(i)
        let item = {
            country: country,
            status: 'arrived',
            creater: i
        }
        dispatch(getAllLogisticProducts(item))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="ჩამოსული"/>
                    <Row>


                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                 {/*   <div className="button-items">
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-primary w-xs waves-effect waves-light"
                                            disabled={props.logistic.preloaderAll}
                                            onClick={handleChangeStatusAll}
                                        >
                                            {
                                                props.logistic.preloaderAll === true
                                                    ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                    : null
                                            }

                                            <span>  გატანილი (ყველა)</span>
                                        </Button>{" "}
                                        <Button

                                            type="button"
                                            color="success"
                                            className="btn btn-success w-lg waves-effect waves-light"
                                            disabled={props.logistic.preloaderChecked}
                                            onClick={handleChangeStatusChecked}

                                        >
                                            {
                                                props.logistic.preloaderChecked === true
                                                    ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                    : null
                                            }
                                            <span>  გატანილი(მხოლოდ მონიშნული)</span>
                                        </Button>
                                        <div style={{marginLeft: '8px'}}>
                                            <div className="square-switch">
                                                <input
                                                    type="checkbox"
                                                    id="square-switch1"
                                                    switch="none"
                                                    checked={sq1}
                                                    onChange={() => {
                                                        setsq1(!sq1)
                                                    }}
                                                />
                                                <label
                                                    htmlFor="square-switch1"
                                                    data-on-label="On"
                                                    data-off-label="Off"
                                                />
                                                <span
                                                    style={{position: 'relative', bottom: '16px', marginLeft: '10px'}}
                                                >სმესების და მაილების დაგზავნა </span>
                                            </div>
                                        </div>
                                    </div>
                                      <Row>
                                        <Col>
                                            <hr/>
                                        </Col>
                                    </Row>

                                    */}

                                    <Row>
                                        <Col>
                                            <label className="col-md-2 col-form-label">ქვეყანა</label>
                                            <select
                                                value={country}
                                                onChange={handleChangeCountry}
                                                className="form-control">
                                                <option value={0}>აირჩიეთ ქვეყანა</option>
                                                <option value={1}>ჩინეთი</option>
                                                <option value={2}>ამერიკა</option>
                                                <option value={3}>თურქეთი</option>
                                            </select>
                                            <br/>
                                       {/*
                                            <div className="button-items">
                                                <Button color="primary"
                                                        onClick={handleChangeCreator.bind(this, 'all')}
                                                        className="btn-soft-primary waves-effect waves-light">ყველა <span
                                                    className="badge bg-soft-danger font-size-12">{props.products.waitingCount}</span></Button>{" "}
                                                <Button color="success"
                                                        // onClick={handleChangeCreator.bind(this, 'user')}
                                                        className="btn-soft-success waves-effect waves-light">თბილისი  <span
                                                        className="badge bg-soft-danger font-size-12">{props.products.productsUserCreaterCount}</span></Button>{" "}
                                                <Button color="info"
                                                        // onClick={handleChangeCreator.bind(this, 'admin')}
                                                        className="btn-soft-info waves-effect waves-light">ქუთაისი
                                                      <span
                                                        className="badge bg-soft-danger font-size-12">{props.products.productsAdminCreaterCount}</span></Button>{" "}
                                            </div>*/}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                onRowSelect={onRowSelect}
                                logisticProducts={props.products.products}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic
    } = state
    return {
        products,
        logistic
    }
};
export default withRouter(connect(mapStateToProps)(Arrived));
